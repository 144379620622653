/* eslint-disable radix */
/* eslint-disable no-param-reassign */
import { format } from 'date-fns';
import { FormHelper } from 'utils';
import { BRANCH_TYPE, CONTACT_ADDED, SCREENPLAYS } from 'utils/constants';

const formatAmendmentDataFormState = (formState, toStringCondition, data, prefix) => new Promise(resolve => {
  Object.keys(data).forEach(key => {
    if (toStringCondition.includes(key)) {
      formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key].toString());
    } else if (key === 'establishments') {
      if (data[key].$values) {
        formState.setField('establishmentNumber', data[key].$values.length);
        formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key].$values);
      } else {
        formState.setField('establishmentNumber', data[key].length);
        formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key]);
      }
    } else if (key === 'regions') {
      formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key].$values);
    } else if (key === 'companyName') {
      formState.setField(`${prefix}StructureName`, data[key]);
    } else if (key === 'contacts') {
      if (formState.values[`${prefix}${FormHelper.capitalize(key)}`] && formState.values[`${prefix}${FormHelper.capitalize(key)}`].length === 0) {
        if (data[key].$values) {
          formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key].$values);
        } else {
          formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key]);
        }
      }
    } else if (key === 'originProofs') {
      formState.setField(`${prefix}${FormHelper.capitalize(key)}`, formState.values[`${prefix}${FormHelper.capitalize(key)}`]);
    } else if (key === 'administrativeDepartment') {
      formState.setField(`${prefix}${FormHelper.capitalize(key)}`, { ...data[key] });
    } else if (key === 'siren' || key === 'siret') {
      formState.setField(`${prefix}${FormHelper.capitalize('identificationNumber')}`, data[key]);
    } else if (key.includes('disbar')) {
      if (key === 'disbarReason') {
        formState.setField('disbarReason', data[key].toString());
      } else if (key === 'disbarStructureCode') {
        formState.setField('disbarRegistrationNumber', data[key]);
      } else {
        formState.setField(key, data[key]);
      }
    } else {
      formState.setField(`${prefix}${FormHelper.capitalize(key)}`, data[key]);
    }
  });
  formState.setField('id', data.id);
  resolve(true);
});

const formatAmendmentData = (object, toStringCondition, data, prefix, formatEstablishmentForMembership) => new Promise(resolve => {
  Object.keys(data).forEach(key => {
    if (toStringCondition.includes(key)) {
      object[`${prefix}${FormHelper.capitalize(key)}`] = data[key].toString();
    } else if (key === 'establishments') {
      const establishments = [];
      if (data[key].$values) {
        object[`${prefix}${FormHelper.capitalize(key)}`] = data[key].$values;
      } else {
        data[key].forEach(e => {
          let establishment = {};
          if (formatEstablishmentForMembership) {
            establishment = {
              ...e
            };
            establishment.branches = {};
            establishment.branches.$values = [
              ...e.branches
            ];
            establishments.push(establishment);
          } else {
            formatAmendmentData(establishment, [], e, 'amendmentEstablishment');
            establishment.amendmentEstablishmentCompany = { ...e.company };
            establishments.push(establishment);
          }
        });
        object[`${prefix}${FormHelper.capitalize(key)}`] = [...establishments];
      }
    } else if (key === 'companyName') {
      object[`${prefix}StructureName`] = data[key];
    } else if (key === 'regions') {
      object[`${prefix}${FormHelper.capitalize(key)}`] = data[key].$values;
    } else if (key === 'natureActivite') {
      object[`${prefix}NatureActivity`] = data[key];
    } else if (key === 'contacts') {
      if (data[key].$values && data[key].$values.length > 0) {
        object[`${prefix}${FormHelper.capitalize(key)}`] = data[key].$values;
      } else if (data[key] && data[key].length > 0) {
        object[`${prefix}${FormHelper.capitalize(key)}`] = data[key];
      } else {
        object[`${prefix}${FormHelper.capitalize(key)}`] = [{
          lastName: '',
          firstName: '',
          function: '',
          phoneNumber: '',
          mail: '',
          status: CONTACT_ADDED // Allow to assign the right modification color in the verification step
        }];
      }
    } else if (key === 'branches') {
      if (data[key].$values) {
        object[`${prefix}${FormHelper.capitalize(key)}`] = data[key].$values;
        object.amendmentEstablishmentIsInActivity = data[key].$values && data[key].$values.length > 0;
      } else {
        object[`${prefix}${FormHelper.capitalize(key)}`] = data[key];
        object.amendmentEstablishmentIsInActivity = data[key] && data[key].length > 0;
      }
    } else if (key === 'siren' || key === 'siret') {
      object[`${prefix}${FormHelper.capitalize('identificationNumber')}`] = data[key];
    } else {
      object[`${prefix}${FormHelper.capitalize(key)}`] = data[key];
    }
  });
  object.id = data.id;
  resolve(true);
});

const formatEstablishment = establishment => {
  const structure = { };
  Object.keys(establishment).forEach(key => {
    if (key.includes('amendmentEstablishment')) {
      const newKey = FormHelper.unCapitalize(key.split('amendmentEstablishment').pop());
      if (newKey !== 'type' && !newKey.includes('$')) {
        if (newKey === 'identificationNumber') {
          structure.siret = establishment[key];
        } else if (newKey === 'registrationNumber') {
          structure.structureCode = establishment[key];
        } else if (newKey === 'contacts') {
          const newContacts = [];
          establishment[key].forEach(contact => {
            delete contact.status;
            newContacts.push({ ...contact });
          });
          structure[newKey] = newContacts;
        } else if (newKey === 'branches') {
          const newBranches = [];
          establishment[key].forEach(branch => {
            delete branch.status;
            delete branch.activity;
            delete branch.situation;
            delete branch.code;
            branch.type = 0;
            branch.date = format(new Date(`01/01/${branch.seasonId}`), 'dd/MM/yyyy');
            newBranches.push({ ...branch });
          });
          structure[newKey] = newBranches;
        } else if (newKey === 'responsibleStructure') {
          structure.responsibleStructureId = establishment[key].id;
        } else if (newKey === 'administrativeDepartment') {
          structure.administrativeDepartmentId = establishment[key].id;
        } else {
          structure[newKey] = establishment[key];
        }
      }
    }
  });
  return structure;
};

const createAmendmentDataToSendFromFormstateDefault = formState => {
  let dataToSend = { };
  if (formState.values.target === 'company') {
    dataToSend.$type = 'CompanyRequest';
    Object.keys(formState.values).forEach(key => {
      if (key.includes('amendmentCompany') && key !== 'amendmentCompany') {
        const newKey = FormHelper.unCapitalize(key.split('amendmentCompany').pop());
        if (newKey !== 'type' && !newKey.includes('$')) {
          if (newKey === 'identificationNumber') {
            dataToSend.siren = formState.values[key];
          } else if (newKey === 'registrationNumber') {
            dataToSend.structureCode = formState.values[key];
          } else if (newKey === 'legalForm') {
            dataToSend[newKey] = Number(formState.values[key]);
          } else if (newKey === 'contacts') {
            const newContacts = [];
            formState.values[key].forEach(contact => {
              delete contact.status;
              newContacts.push({ ...contact });
            });
            dataToSend[newKey] = newContacts;
          } else if (newKey === 'branches') {
            const newBranches = [];
            formState.values[key].forEach(branch => {
              delete branch.status;
              delete branch.activity;
              delete branch.situation;
              newBranches.push({ ...branch });
            });
            dataToSend[newKey] = newBranches;
          } else if (newKey === 'administrativeDepartment' || newKey === 'responsibleStructure') {
            dataToSend[`${newKey}Id`] = formState.values[key].id;
          } else if (newKey !== 'administrativeDepartmentId' && newKey !== 'responsibleStructureId') {
            dataToSend[newKey] = formState.values[key];
          }
        }
      }
    });
    if (formState.values.amendmentEstablishments.length > 0) {
      const establishments = [];
      formState.values.amendmentEstablishments.forEach(establishment => {
        establishments.push({ $type: 'EstablishmentRequest', ...formatEstablishment(establishment) });
      });
      dataToSend = [dataToSend, ...establishments];
    }
  } else {
    const establishment = formState.values.amendmentEstablishments[0];
    dataToSend = { $type: 'EstablishmentRequest', ...formatEstablishment(establishment) };
  }
  return dataToSend;
};

const createAmendmentDataToSendFromFormstateTakeover = formState => {
  if (formState.values.target === 'establishment') {
    const companies = [];
    const establishments = [];
    formState.values.buyers.forEach(buyer => {
      if (buyer.$type === 'EstablishmentResponse') {
        establishments.push({
          $type: 'EstablishmentRequest',
          id: buyer.id,
          siret: buyer.newIdentificationNumber,
          contacts: [buyer.newContact],
          correspondingAddress: { ...buyer.newCorrespondingAddress }
        });
      } else {
        companies.push(buyer);
      }
    });
    establishments.forEach(establishment => {
      companies.forEach(company => {
        if (company.amendmentCompanyEstablishmentsIds.includes(establishment.id)) {
          establishment.parentId = company.amendmentCompanyId;
        }
      });
    });
    return [...establishments];
  }
  return [];
};

const getBranchTypeByScreenplay = formState => {
  switch (formState.values.amendmentScreenplay) {
  case SCREENPLAYS.COMPANY_ENROLMENT:
    return BRANCH_TYPE.JOINING;
  case SCREENPLAYS.COMPANY_RESIGNATION:
    return BRANCH_TYPE.RESIGNATION;
  case SCREENPLAYS.ESTABLISHMENT_REACTIVATION:
    return BRANCH_TYPE.ACTIVITY_RESTARTING;
  case SCREENPLAYS.ESTABLISHMENT_SUSPENSION:
    return BRANCH_TYPE.ACTIVITY_SUSPENSION;
  default:
    return BRANCH_TYPE.UNKNOWN;
  }
};

const createAmendmentDataToSendFromFormstateActivityManagement = formState => {
  let dataToSend = {};
  const establishment = formState.values.amendmentEstablishments[0];

  const modifiedBranches = [];
  establishment.amendmentEstablishmentBranches.forEach(branch => {
    const modifiedBranch = { ...branch };
    modifiedBranch.date = `01/01/${branch.seasonId}`;
    delete modifiedBranch.activityModificationDate;
    if (modifiedBranch.status === 'modified') {
      if (branch.activityModificationDate !== '') {
        modifiedBranch.type = getBranchTypeByScreenplay(formState);
        modifiedBranch.activityModificationDate = branch.activityModificationDate;
      }
      modifiedBranch.activityStatus = branch.activityStatus;
    }
    modifiedBranches.push(modifiedBranch);
  });
  if (modifiedBranches.length > 0) {
    dataToSend = { $type: 'EstablishmentRequest', id: establishment.id, branches: [...modifiedBranches] };
  }
  return dataToSend;
};

const createAmendmentDataToSendFromFormstateMembership = formState => {
  const dataToSend = [];

  formState.values.amendmentCompanyEstablishments.forEach(establishment => {
    const modifiedBranches = [];
    establishment.branches.$values.forEach(branch => {
      const modifiedBranch = { ...branch };
      modifiedBranch.date = `01/01/${branch.seasonId}`;
      if (modifiedBranch.status === 'modified') {
        modifiedBranch.type = getBranchTypeByScreenplay(formState);
      }
      modifiedBranches.push(modifiedBranch);
    });
    if (modifiedBranches.length > 0) {
      dataToSend.push({ $type: 'EstablishmentRequest', id: establishment.id, branches: [...modifiedBranches] });
    }
  });

  return dataToSend;
};

const createAmendmentDataToSendFromFormstateDisbar = formState => {
  const dataToSend = {
    $type: formState.values.target === 'company' ? 'CompanyRequest' : 'EstablishmentRequest',
    disbarReason: Number(formState.values.disbarReason)
  };

  if (formState.values.disbarDate)dataToSend.disbarDate = formState.values.disbarDate;
  if (formState.values.disbarStructureName)dataToSend.disbarStructureName = formState.values.disbarStructureName;
  if (formState.values.disbarRegistrationNumber)dataToSend.disbarStructureCode = formState.values.disbarRegistrationNumber;

  return dataToSend;
};

const getEstablishmentIdsByParentIdInAmendment = (idParent, establishmentsInAmendment) => {
  const establishmentsId = [];
  establishmentsInAmendment.forEach(establishment => {
    if (establishment.amendmentEstablishmentNewParentId === idParent) establishmentsId.push(establishment.id);
  });
  return establishmentsId;
};

export const AmendmentHelper = {
  formatAmendmentDataFormState,
  formatAmendmentData,
  createAmendmentDataToSendFromFormstateDefault,
  createAmendmentDataToSendFromFormstateTakeover,
  createAmendmentDataToSendFromFormstateMembership,
  createAmendmentDataToSendFromFormstateDisbar,
  createAmendmentDataToSendFromFormstateActivityManagement,
  getEstablishmentIdsByParentIdInAmendment
};